<template>
    <div class="userService">
        <!--            导航-->
        <van-nav-bar class="nav" title="隐私协议" left-arrow @click-left="leftReturn"/>
        <div class="placeholder"></div>
        <!--        服务详情-->
        <div id="details"></div>
    </div>
</template>

<script>
    import { NavBar} from 'vant';
    import {responseUtil} from "../../libs/rongxunUtil";
    import {bannerLinkUserPrivacyPolicy} from "../../getData/getData";
    export default {
        components: {
            [NavBar .name]:NavBar,
        },
        data() {
            return {
                detail : {}

            }
        },
        mounted() {
            this.initData()
        },
        methods :{
            leftReturn(){
                this.$router.go(-1)
            },
            initData(){
                let that = this
                let initData = {}
                initData.textType = '2'
                bannerLinkUserPrivacyPolicy(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.detail=response.data.data.textContent
                        if(response.data.data.textContent == undefined || response.data.data.textContent == ""){
                            that.detail= "暂无内容"
                        }
                        document.getElementById("details").innerHTML=that.detail
                    })
                })
            }

        }
    }
</script>

<style lang="less" scoped >
    .nav{
        position: fixed;
        width: 100%;
    }
    .placeholder{
        height: 30px;
    }
</style>